<!--
 Copyright 2021 Semantic STEP Technology GmbH, Germany & DCT Co., Ltd. Tianjin, China
-->

<template>
  <tr>
    <td>
      <router-link
        :to="{ name: 'SSTDataset', params: { dsID: this.itemRef } }"
        >{{ displayId }}</router-link
      >
    </td>
    <td><span v-if="modified">&lowast;</span></td>
    <td>{{ type }}</td>
    <td>{{ iriNodeCount }}</td>
    <td>{{ importCount }}</td>
    <td>{{ importedByCount }}</td>
  </tr>
</template>

<script>
import startsWith from "lodash.startswith";
import { useDatasetEditSessions } from "../stores/dsEditSessions";
export default {
  props: {
    id: { required: true, type: String },
    uri: { required: true, type: String },
    type: { required: true, type: String },
    iriNodeCount: { required: true, type: Number },
    importCount: { required: true, type: Number },
    importedByCount: { required: true, type: Number },
  },
  setup() {
    const dsEditSessions = useDatasetEditSessions();
    return {
      dsEditSessions: dsEditSessions.sessions,
      dsServiceSessions: dsEditSessions.serviceSessions,
    };
  },
  computed: {
    modified() {
      return (
        this.dsServiceSessions.has(this.id) || this.dsEditSessions.has(this.id)
      );
    },
    displayId() {
      return startsWith(this.uri, "urn:uuid:") ? this.id : this.uri;
    },
  },
  data() {
    return {
      itemRef: this.id,
    };
  },
};
</script>

<style>
</style>