<!--
 Copyright 2021 Semantic STEP Technology GmbH, Germany & DCT Co., Ltd. Tianjin, China
-->

<template>
  <p class="name truncate">SST Repository</p>
  <dataset-list></dataset-list>
</template>

<script>
import DatasetList from "../components/DatasetList.vue";

export default {
  name: "SSTRepository",
  components: {
    DatasetList,
  },
};
</script>

<style>
</style>