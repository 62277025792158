<!--
 Copyright 2021 Semantic STEP Technology GmbH, Germany & DCT Co., Ltd. Tianjin, China
-->

<template>
  <h2 class="subheader">Datasets</h2>
  <div class="table-container">
    <div class="right-aligned-text">
      <input
        ref = "inputFile"
        @change = "uploadFile"
        type="file" 
        accept=".ttl,.xml"
        style="display: none;"
       />
      <form>
        <input
          class="search"
          :class="{ error: filterQueryHasError }"
          ref="filterQuery"
          v-model="filterQuery"
          placeholder="Search"
          type="text"
          required
          autofocus
        />
        <button
          class="close-icon"
          v-on:click="resetFetchData()"
          type="reset"
        ></button>
        <input type="button" value="Upload" @click="upload" class="upload"/>

      </form>
    </div>
    <table v-if="haveDatasets()" class="list">
      <thead>
        <th>UUID</th>
        <th>&lowast;</th>
        <th>Type</th>
        <th># of<br />Nodes</th>
        <th># of Direct<br />Imports</th>
        <th># of Directly<br />Imported by</th>
      </thead>
      <tbody v-for="item in datasets" :key="item.id">
        <dataset-item
          :id="item.id"
          :uri="item.uri"
          :type="item.type"
          :iriNodeCount="item.iriNodeCount"
          :importCount="item.imports !== undefined ? item.imports.length : 0"
          :importedByCount="
            item.importedBy !== undefined ? item.importedBy.length : 0
          "
        ></dataset-item>
      </tbody>
    </table>
    <div v-else>
      <em
        >No datasets <span v-if="filterQuery">match</span
        ><span v-else>available</span></em
      >
    </div>
  </div>
</template>

<script>
import DatasetItem from "./DatasetItem.vue";
import { useGlobalInfo } from "../stores/globalInfo";
import { useRecentStore } from "../stores/recent";
import startsWith from "lodash.startswith";
import sortBy from "lodash.sortby";
export default {
  components: {
    DatasetItem,
  },
  setup() {
    const recentStore = useRecentStore();
    const globalInfo = useGlobalInfo();
    return { globalInfo, recentStore };
  },
  data() {
    return {
      filterQuery: this.recentStore.recentDatasetFilterQuery,
      filterQueryHasError: false,
      datasets: null,
      fetchDataCnt: 0,
    };
  },
  created() {
    this.$watch(
      () => this.filterQuery,
      () => this.fetchData(),
      { immediate: true }
    );
  },
  methods: {
    uploadFile(that){
      // console.log(that.target.files[0])
      var formData = new FormData()
      formData.append('file',that.target.files[0])
      formData.append('file_name',that.target.files[0].name)
      this.axios({
        method: "post",
        data : formData,
        url: "/import"
      })
      .then(() => {
        this.fetchData()
      })
    },
    upload(){
      const inputFile = document.querySelector("input[type='file']")
      inputFile.click()
    },
    fetchData() {
      this.fetchDataCnt++;
      if (this.fetchDataCnt > 1) {
        return;
      }
      this.fetchDataCnt = 1;
      const filterQuery =
        this.filterQuery !== undefined && this.filterQuery !== ""
          ? this.filterQuery
          : undefined;
      this.axios({
        method: "get",
        url:
          "/dataset" +
          (filterQuery !== undefined
            ? "?q=" + encodeURIComponent(this.filterQuery)
            : ""),
        validateStatus: function (status) {
          return (status >= 200 && status < 300) || status === 422;
        },
      })
        .then((response) => {
          if (response.status === 422) {
            this.filterQueryHasError = true;
          } else {
            this.datasets = sortBy(response.data.datasets, [
              (o) => (startsWith(o.uri, "urn:uuid:") ? o.id : o.uri),
            ]);
            this.recentStore.recentDatasetFilterQuery = filterQuery;
            this.globalInfo.updateLoggedInUser(response.data);
            this.filterQueryHasError = false;
          }
          this.afterFetchData();
        })
        .catch((error) => {
          this.afterFetchData();
          throw error;
        });
    },
    afterFetchData() {
      this.fetchDataCnt--;
      if (this.fetchDataCnt > 0) {
        this.fetchDataCnt = 0;
        this.fetchData();
      }
    },
    resetFetchData() {
      this.filterQuery = "";
      this.$refs.filterQuery.focus();
    },
    haveDatasets() {
      return (this.datasets && this.datasets.length > 0) || false;
    },
  },
};
</script>

<style scoped>

.upload{
  position: relative;
  margin-left: 3px;
}
.search {
  position: relative;
  font-size: 14px;
  width: 28em;
  padding: 3px 16px 3px 3px;
  margin: 3px 0;
  border: 1px solid #000;
  border-radius: 3px;
  outline: none;
  -webkit-appearance: none;
}

.search.error {
  background-color: #ffcfc6;
}

.close-icon {
  position: relative;
  padding: 17px 0 0 0;
  margin: 0;
  border: 0px solid transparent;
  background-color: transparent;
  display: inline-block;
  vertical-align: middle;
  outline: 0;
  cursor: pointer;
}

.close-icon:after {
  content: "\2715";
  display: block;
  width: 12px;
  position: absolute;
  z-index: 1;
  right: 6px;
  top: 0;
  margin: auto;
  padding: 0;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
}
.search:not(:valid) ~ .close-icon {
  display: none;
}
</style>